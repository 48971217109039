<template>
  <div>
    <b-card
      no-body
      class="mb-0"
      v-if="detail.id !== undefined"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <feather-icon icon="UserIcon" /> <b>{{ detail.creator.name }}</b>
            <feather-icon icon="CalendarIcon" class="ml-2"/> {{ detail.created_at }}
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-end"
          >
            <b-button :to="{ name: 'announcement'}"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-form
                variant="secondary"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50"
                />
                <span>Kembali</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <hr>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <h2>{{ detail.title }}</h2>        
          </b-col>
          <b-col
            cols="12"
            md="12" class="mt-3"
          >
            <div v-html="detail.content"></div>   
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BLink
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      detail: {},
    }
  },
  mounted() {
    this.getAnnouncementDetail()
  },
  methods: {
    getAnnouncementDetail() {
      this.$http.get(`/v1/announcement/${this.$route.params.id }`)
        .then(response => {
          this.detail = response.data.data
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
  }
}
</script>
